export default function footer(){
    return(
      <>
        <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12 pb-3'>
              <img className='pt-3 pb-3' src='01.png' alt='sarkariexam' />
            </div>
            <div className='col-md-4 pb-3'>
              <h5>Practicing constantly with the Sarkari Result exams avoids this exam stress, and students will get used to the exam pattern. Since they already know the exam structure by practicing many times, the final exam will be more comfortable for them and 5k student Registered.</h5>
            </div>
            <div className='col-md-4 pb-3'>
              <h4 style={{ fontWeight: "700", color: "#fff" }}>Products</h4>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h5>Test Series</h5>
                  <h5>Tests and Quizzes</h5>
                  <h5>YouTube Video</h5>
                  <h5>Practice</h5>
                </div>
                <div className='col-md-6 col-6'>
                  <h5>Blog</h5>
                  <h5>Refer & Earn</h5>
                  <h5>Books</h5>
                  <h5>Exams</h5>
                </div>
              </div>

            </div>
            <div className='col-md-4'>
              <h4 style={{ fontWeight: "700", color: "#fff" }}>Location</h4>
              <h5>2nd Floor, Hari Om Tower, Kantatoli,Ranchi (Jharkhand)</h5>
              <h5>Email : info@sarkariresultexam.com</h5>
              <h5>Helpline : +919876543210</h5>
            </div>
          </div>
        </div>
      </footer>
      <section className='copyy'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-6">
              <p style={{ color: "#fff", textAlign: "center" }} className="mb-1 mt-1">
                Copyrights 2023 <a style={{ fontWeight: "700", color: "#fff" }} href="/">Sarkari Resluts Exam</a>. All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6">
              <p style={{ color: "#fff", textAlign: "center" }} className="mb-1 mt-1">
                Designed By{" "}
                <a style={{ fontWeight: "700", color: "#fff" }} href="https://www.brightcodess.com/">Brightcode Software Services.</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      </>
    )
}