import Header from '../components/header';
import Footer from '../components/footer';

export default function About(){
  return(
    <>
      <Header />
      <h2>About Page</h2>
      <Footer />
    </>
  )
}