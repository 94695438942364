import Header from '../components/header';
import Footer from '../components/footer';
import App from '../App.css';

export default function Home() {
  return (
    <>
      <Header />
      <section className='desktop'>
        <section className='carro'>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="band1.jpg" className="d-block w-100" alt="band1.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band2.jpg" className="d-block w-100" alt="band2.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band3.jpg" className="d-block w-100" alt="band3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section>

      <section className='mobile'>
        <section className='carro'>
          <div id="carouselExampleControlssaiiias" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="banm1.jpg" className="d-block w-100" alt="banm1.jpg" />
              </div>
              <div className="carousel-item">
                <img src="banm2.jpg" className="d-block w-100" alt="banm2.jpg" />
              </div>
              <div className="carousel-item">
                <img src="banm3.jpg" className="d-block w-100" alt="banm3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlssaiiias" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControlssaiiias" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section>





      <section className='welcome'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12 text-center'>
              <p className='we'>Welcome To</p>
              <p className='indi'>India’s No. 1</p>
              <p className='in'>Sarkari Result Exam Portal </p>
              <p className='for'>For Completing Your Exam’s Preparation</p>
              <p className='dow'>Download Our App From</p>
            </div>
            <div className='col-md-2 col-5'>
              <center><img src="31.png" className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
            </div>
            <div className='col-md-2 col-5'>
              <center><img src="32.png" className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
            </div>
          </div>
          <div className='row justify-content-center pt-4'>
            <div className='col-md-3 col-8'>
              <center><img src="04.png" className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src="05.png" className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src="06.png" className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src="07.png" className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
          </div>
        </div>
      </section>








      <section className='vcvc'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-1 col-2'>
              <img src='08.png' style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
              <h3 className='uu'><span>YouTube Video</span> Support</h3>
            </div>
            <div className='col-md-12'>
              <div id="carouselExampleControlsfyfklulh" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>
                  <div className="carousel-item">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>

                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="prev">
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Previous</span> */}
                  <img src='22Copy.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="next">
                  {/* <span className="carousel-control-next-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Next</span> */}
                  <img src='22.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='lloo'>
          <div className='container'>
            <div className='row justify-content-center'>

              <div className='col-md-12'>

                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-ssc" role="tabpanel" aria-labelledby="pills-ssc-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gXhdno374Og?si=BzoVmxPYExzFGxR7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/-1einz1-8OU?si=h0Dt10N3JRgscYMa" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/fdDa2jeGx5g?si=lQIWEcRhIKKelEIY" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-railway" role="tabpanel" aria-labelledby="pills-railway-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/8fXtK8O8YeA?si=_bHmmzikY3C_GwB2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/5Gy7LAG7uV8?si=FYC_lP1NEn8r1yIM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/nBjdUJlRZUc?si=B3qqjiiZi1c28UzM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-defence" role="tabpanel" aria-labelledby="pills-defence-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/7y_tlPQFtk0?si=-gOwo4tWlXTVgX4Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CZyRrNgb6qU?si=yPDmN229QhOJDcW7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CvjcN6ErK34?si=bIAIrA00iZnoYhL2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-banking" role="tabpanel" aria-labelledby="pills-banking-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/BGN5IUSLk_c?si=4ObMw-AgAFv15hcR" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gpA7BpubhdU?si=CTFTlQGbGnPvc8VW" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/EpTupTIKRCM?si=fbDh6oDaf3isBA1w" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-states" role="tabpanel" aria-labelledby="pills-states-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/xQyE0Qzz6XE?si=DebtTLR-6PBN37FG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/zNPKQjAFsvc?si=9S4pTuEs85dzrLmz" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/KO3gtJh9XV8?si=2HmWP-2bINCFz8zm" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                </div>
                <div className='col-md-12'>
                  <p className='btn btn-lg btn-block mt-3 mb-2' style={{ borderRadius: "20px", background: "#fff", color: "#898989", fontSize: "25px", fontWeight: "600" }}>A Complete YouTube Video Support For Your Better Preparation</p>
                </div>
              </div>



            </div>
          </div>
        </section>
      </section>



















      <section className="news">
        <div className="container">
          <div className="row justify-content-center">
            <div className='col-md-1 col-2'>
              <img src='11.png' style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
              <h3 className='uu'><span>Test Series </span> Support</h3>
            </div>
            <div className="col-md-3 col-10">
              <div className="abt">
                <img src='12.png' style={{ width: "50%" }} alt='sarkariexam' />
                <h5>SSC GD Constable Mock Test 2024 (NEW)</h5>
                <p className='pt-0 pb-0 mb-2' style={{ color: "rgb(24 79 168)", fontWeight: 600 }}>Total Test : 500 | 5 Test Free</p>
                <p className='pt-1 pb-0 mb-0'>Learn in Language</p>
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>Hindi & English</strong></p>
                <p className='pt-0 pb-0 mb-2'>185 Chapter Test</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>150 PYCT</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>120 Sectional Test</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>+45 extra Tests</strong></p>
              </div>
              <p className='oo'>Explore Total Tests</p>
            </div>
            <div className="col-md-3 col-10">
              <div className="abt">
                <img src='13.png' style={{ width: "50%" }} alt='sarkariexam' />
                <h5>SSC CGL Tier l & ll Mock Test 2024 (New)</h5>
                <p className='pt-0 pb-0 mb-2' style={{ color: "rgb(24 79 168)", fontWeight: 600 }}>Total Test : 800 | 5 Test Free</p>
                <p className='pt-1 pb-0 mb-0'>Learn in Language</p>
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>Hindi & English</strong></p>
                <p className='pt-0 pb-0 mb-2'>185 Chapter Test (Tier l)</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>150 Sectional Tests (Tier ll)</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>80 Full Tests (Tier l)</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>+520 extra Tests</strong></p>
              </div>
              <p className='oo'>Explore Total Tests</p>
            </div>
            <div className="col-md-3 col-10">
              <div className="abt">
                <img src='14.png' style={{ width: "50%" }} alt='sarkariexam' />
                <h5>SCIR ASO / SO Mock Test 2024 (NEW)</h5>
                <p className='pt-0 pb-0 mb-2' style={{ color: "rgb(24 79 168)", fontWeight: 600 }}>Total Test : 300 | 5 Test Free</p>
                <p className='pt-1 pb-0 mb-0'>Learn in Language</p>
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>Hindi & English</strong></p>
                <p className='pt-0 pb-0 mb-2'>80 Chapter Test</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>136 Chapter Tests (Paper ll)</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>11 Full Tests (Paper l)</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>+73 extra Tests</strong></p>
              </div>
              <p className='oo'>Explore Total Tests</p>
            </div>
            <div className="col-md-3 col-10">
              <div className="abt">
                <img src='15.png' style={{ width: "50%" }} alt='sarkariexam' />
                <h5>IB ASCO Grade ll Mock Test 2023 (New)</h5>
                <p className='pt-0 pb-0 mb-2' style={{ color: "rgb(24 79 168)", fontWeight: 600 }}>Total Test : 400 | 5 Test Free</p>
                <p className='pt-1 pb-0 mb-0'>Learn in Language</p>
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>Hindi & English</strong></p>
                <p className='pt-0 pb-0 mb-2'>150 Chapter Test</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>80 Sectional Tests</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'>40 Full Test</p>
                <hr style={{ border: "1px solid grey", margin: "0px" }} />
                <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>+45 extra Tests</strong></p>
              </div>
              <p className='oo'>Explore Total Tests</p>
            </div>

          </div>
        </div>
      </section>

      <section className='iimm'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <img src='20.jpg' style={{ width: "100%" }} alt='sarkariexam' />
            </div>
            <div className='col-md-6'>
              <h3 style={{ color: "#e40612" }}>Register Now on Sarkari Result Exams and Enjoy 500+ Exams</h3>
              <h6 className='pt-2 pb-2'>Get unlimited access to the most relevant Mock Tests | Test Series | YouTube Video etc with our Sarkari Result Exams</h6>
              <div className='row justify-content-start'>
                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src='19.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>All India Rank</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src='16.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>Latest Test Series</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src='18.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>Latest Exam Patterns</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src='17.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>YouTube Video Support</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <p className='btn btn-lg btn-block' style={{ borderRadius: "20px", background: "#e40612", color: "#fff", fontSize: "25px" }}>Register Now</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className='vcvc rr' style={{ background: "url(27.png)", backgroundAttachment: "fixed" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-1 col-2'>
              <img src='11.png' style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
              <h3 className='uu'><span>Our Popular Exam </span> Support</h3>
            </div>
            <div className='col-md-12'>
              <div id="carouselExampleControlsfyfklulhefwf" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-sscd-tab" data-toggle="pill" data-target="#pills-sscd" type="button" role="tab" aria-controls="pills-sscd" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-railwayyy-tab" data-toggle="pill" data-target="#pills-railwayyy" type="button" role="tab" aria-controls="pills-railwayyy" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-defenceee-tab" data-toggle="pill" data-target="#pills-defenceee" type="button" role="tab" aria-controls="pills-defenceee" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-bankinggg-tab" data-toggle="pill" data-target="#pills-bankinggg" type="button" role="tab" aria-controls="pills-bankinggg" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-statesss-tab" data-toggle="pill" data-target="#pills-statesss" type="button" role="tab" aria-controls="pills-statesss" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>
                  <div className="carousel-item">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-sscd-tab" data-toggle="pill" data-target="#pills-sscd" type="button" role="tab" aria-controls="pills-sscd" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-railwayyy-tab" data-toggle="pill" data-target="#pills-railwayyy" type="button" role="tab" aria-controls="pills-railwayyy" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-defenceee-tab" data-toggle="pill" data-target="#pills-defenceee" type="button" role="tab" aria-controls="pills-defenceee" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-bankinggg-tab" data-toggle="pill" data-target="#pills-bankinggg" type="button" role="tab" aria-controls="pills-bankinggg" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-statesss-tab" data-toggle="pill" data-target="#pills-statesss" type="button" role="tab" aria-controls="pills-statesss" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>

                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulhefwf" data-slide="prev">
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Previous</span> */}
                  <img src='22Copy.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulhefwf" data-slide="next">
                  {/* <span className="carousel-control-next-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Next</span> */}
                  <img src='22.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='lloo'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-12'>

                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-sscd" role="tabpanel" aria-labelledby="pills-sscd-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">SSC EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">SSC EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">SSC EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-railwayyy" role="tabpanel" aria-labelledby="pills-railwayyy-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">RAILWAY EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">RAILWAY EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">RAILWAY EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-defenceee" role="tabpanel" aria-labelledby="pills-defenceee-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">DEFENCE EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">DEFENCE EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">DEFENCE EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-bankinggg" role="tabpanel" aria-labelledby="pills-bankinggg-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">BANKING EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">BANKING EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">BANKING EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="tab-pane fade" id="pills-statesss" role="tabpanel" aria-labelledby="pills-statesss-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">STATES EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">STATES EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", backGround: "red" }}>
                              <div className="card-body" style={{ backGround: "red!important" }}>
                                <div className="row">
                                  <div className='col-md-4 col-3'>
                                    <img src='12.png' style={{ width: "100%" }} />
                                  </div>
                                  <div className='col-md-8 col-9'>
                                    <h5 className="card-title text-right">STATES EXAM</h5>
                                    {/* <img src='10.png'/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                </div>

              </div>
              <div className='col-md-5'>
                <p className='btn btn-lg btn-block mt-3 mb-2' style={{ borderRadius: "68px", background: "linear-gradient(45deg, #6a6de2, #8ad1c6)", color: "rgb(255 255 255)", fontSize: "25px", fontWeight: "600" }}>Explore All Exams</p>
              </div>


            </div>
          </div>
        </section>
      </section>

      {/* borderRadius: "68px", background: "linearGradient(45deg, #6a6de2, #8ad1c6)", color: "rgb(255 255 255)", fontSize:"25px", fontWeight: "600" */}











































      <section className='aza'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h3>Reason of Choosing</h3>
              <h4>Sarkari Result Exams</h4>
              <hr style={{ border: "5px solid orange", width: "20%", float: "left", margin: "0px 0px 5px 0px" }} /><br></br>
              <p style={{ textAlign: "justify" }}>Your Gateway to Government Jobs. Stay updated with the latest notifications, admit cards, exam dates, and results for various government exams across India. Access valuable information on recruitment processes for positions in banking, railways, defense, education, and more. Sarkari Result Exam offers comprehensive insights into exam patterns, previous papers, and result announcements, aiding aspirants in their pursuit of securing prestigious government positions.</p>
            </div>
            <div className='col-md-8'>
              <div className='row justify-content-start'>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh1'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src='23.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>All India Rank</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Learn from the best Test Series in the most engaging yet simplified ways.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh2'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src='24.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>Latest Test Series</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Fee the thrill of a real exam improve your time & management skills.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh2'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src='25.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>Latest Exam Patterns</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Choose the Language & Learn in the language you are most comfortable with. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh1'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src='26.png' style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>YouTube Video Support</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Video Support for your best preparation. you will feel like you are studying in class.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>




















      <section className='bggb' style={{ paddingLeft: "30px" }}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-4 col-12 pt-5 pb-4' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <p className='pp1'>Start Your Preparation Journey with Our</p>
              <p className='pp2'>Sarkari Result Exam App or Web Portal</p>
              <img className='' src='29.png' style={{ width: "90%" }} alt='sarkariexam' />
              <p className='pp3'>Download our App from</p>
              <div className='row'>
                <div className='col-md-6 col-4'>
                  <center><img src="31.png" className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
                </div>
                <div className='col-md-6 col-4'>
                  <center><img src="32.png" className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
                </div>
              </div>
            </div>
            <div className='col-md-8 col-12'>
              <img className='' src='30.png' style={{ width: "100%" }} alt='sarkariexam' />
            </div>
          </div>
        </div>
      </section>
      <Footer />


    </>
  )
}